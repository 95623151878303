import React, { useState, useEffect } from "react";
import { graphql } from "gatsby";
import {
  Link,
  Trans,
  useTranslation,
  useI18next,
} from "gatsby-plugin-react-i18next";
import MapMenu from "../../images/logos/map-menu.svg";
import TowerMenu from "../../images/logos/tower-menu.svg";
import Ogham from "../../images/logos/ogham.svg";
import Slide from "react-reveal/Slide";
import Basic from "../../pages/components/basic";

const Navbar = () => {
  const [top, setTop] = useState(true);

  useEffect(() => {
    const scrollHandler = () => {
      window.pageYOffset > 10 ? setTop(false) : setTop(true);
    };
    window.addEventListener("scroll", scrollHandler);
    return () => window.removeEventListener("scroll", scrollHandler);
  }, [top]);

  const { languages, changeLanguage } = useI18next();
  const { t } = useTranslation();

  return (
    <div className="max-w-max bg-waterford-ocean">
      <nav
        className={`fixed w-full z-50 md:bg-opacity-80 transition duration-300 ease-in-out ${
          !top && "bg-waterford-ocean s-lg"
        } block lg:hidden`}
      >
        <div className="grid grid-cols-4 py-2 text-xs w-screen">
          <Link className="home p-1" to="/">
            <div className="">
              <img className="h-12 text-center mx-auto" src={TowerMenu} />
              <p className="text-center text-white font-heading pt-2 uppercase">
                {t("Home")}
              </p>
            </div>
          </Link>

          <Link className="map p-1" to="/mapview">
            <div className="">
              <img className="h-12  mx-auto" src={MapMenu} />
              <p className="text-center text-white font-heading pt-2 uppercase">
                {t("Map")}
              </p>
            </div>
          </Link>

          <div className="about p-1">
            <Basic />
            <p className="text-center text-white font-heading pt-1 uppercase">
              {t("About")}
            </p>
          </div>

          <div className="p-1 text-white text-center text-white font-heading">
            <div class="dropdown">
              <span className="w-full">
                <img className="h-12  mx-auto" src={Ogham} />
              </span>
              <p className="text-center text-white font-heading pt-2 uppercase">
                  {t("Translate")}
                </p>
              <div class="dropdown-content">
              <Slide top>
                <div className="languages text-waterford-ocean font-semibold bg-white border-1 grid grid-cols-1">
                  {languages.map((lng) => (
                    <a
                      key={lng}
                      className="btn-lang border border-1 border-waterford-ocean py-1 text-xl uppercase "
                      href="#"
                      onClick={(e) => {
                        e.preventDefault();
                        changeLanguage(lng);
                      }}
                    >
                      {lng}
                    </a>
                  ))}
                </div>
                </Slide>

              </div>
            </div>
          </div>
        </div>
      </nav>
    </div>
  );
};

export default Navbar;

export const query = graphql`
  query ($language: String!) {
    locales: allLocale(filter: { language: { eq: $language } }) {
      edges {
        node {
          ns
          data
          language
        }
      }
    }
  }
`;
