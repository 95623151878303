import * as React from "react";
import "../../css/index.css";
import WCC from "../../images/logos/WCC.svg";
import AncientEast from "../../images/logos/ancient-east.svg";
import WhereIreland from "../../images/logos/where-ireland.svg";
import USI from "../../images/logos/USI.svg";
import Imvizar from "../../images/logos/imvizar.svg";

export default function Footer() {
  return (
    <div className="mx-auto bg-waterford-ocean p-4 lg:hidden">
      <div className="grid grid-cols-2 md:px-16">
        <img className="mx-auto px-2 h-32 md:h-24" loading="lazy" alt="Ireland's Ancient East" src={AncientEast}></img>
        <img className="mx-auto px-2 h-32 md:h-24" loading="lazy" alt="Visit Waterford" src={WhereIreland}></img>
      </div>
      <img className="mx-auto p-4 h-24 md:px-16" loading="lazy" src={WCC} alt="Waterford City Council"></img>
      <div className="grid grid-cols-3 md:px-16">
        <p className="text-white font-heading px-4 py-5 text-center text-sm">Powered by</p>
        <img className="mx-auto p-4 my-auto h-14" loading="lazy" src={USI} alt="Urban Scale Interventions"></img>
        <img className="mx-auto p-4 h-16" loading="lazy" src={Imvizar} alt="Imvizar"></img>
      </div>
    </div>
  );
}
