import * as React from 'react'
import Navbar from './navbar'
import Footer from './footer'
import '../../css/index.css'


export default function Layout ({children}) {
  return (
    <div className="layout">
      <Navbar/>
      <div className="">
        { children }
      </div>
      <Footer/>
    </div>
  )
}